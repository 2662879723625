import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import ToasterGen from "../../helper/Toaster";
import { callsData } from "../../redux/dataStore/callsSlice";
import moment from "moment";
import { DEFAULT_COOKIE_GETTER } from "../../helper/Cookie";
import getApi from "../../helper/getApi";

// Define types
interface TimerData {
  [key: string]: string;
}

const Main = () => {
  const [userList, setUserList] = useState<string[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectTeams, setSelectTeams] = useState<any[]>([]);
  const [managers, setManagers] = useState<string[]>([]);
  const [selectedManager, setSelectedManager] = useState<string>("");
  const [managerTeam, setManagerTeam] = useState<string[]>([]);
const userRole = JSON.parse(localStorage.getItem("user_data") || '{}').role;
const isAdmin = userRole === "Admin";
  const dataCalls = useSelector((state: RootState) => state.calls);
  const [elapsedTimes, setElapsedTimes] = useState<TimerData>({});
  const dispatch = useDispatch<AppDispatch>();
  const handleManagerChange = (manager: string) => {
    // Find the team associated with the selected manager
    const team = selectTeams.find(team => Object.keys(team).includes(manager));
    if (team) {
      // Get the user values directly and convert them to strings
      const userValues = team[manager].map((user: any) => String(user)); // Ensure all values are strings
      setManagerTeam(userValues); // Set the state with user values
      
    } else {
      setManagerTeam([]); // Clear if no team found
    }
    setSelectedManager(manager); // Update the selected manager
  };

  
  const handleUserChange = (user: string) => {
    
    setSelectedUsers((prev) => 
      prev.includes(user) ? prev.filter(u => u !== user) : [...prev, user]
    );
  };

  useEffect(() => {
    async function fetchUsers() {
      try {
        const activationToken = await DEFAULT_COOKIE_GETTER('access_token');
        const headers = {
          'Authorization': 'Bearer ' + activationToken,
          'Accept': '*/*',
          'Access-Control-Allow-Origin': true,
        };

        const dataEmail = await DEFAULT_COOKIE_GETTER('user');
        const email = JSON.parse(dataEmail || '{}').email;
        const role = JSON.parse(dataEmail || '{}').role;

        const response: any = await getApi('auth/get_team_by_email', encodeURIComponent(JSON.stringify({ email, role })), headers);

        setUserList(response.data);
        setSelectTeams(response.teams);

        // Extract managers
        const managersList: string[] = response.teams.map((team: object) => Object.keys(team)[0]);
        setManagers(managersList);
      } catch (error) {
        console.error(error);
      }
    }

    fetchUsers();
  }, []);

  const getElapsedTime = (startTime: string, previousElapsedTime: string = "00:00:00") => {
    const start = moment(startTime);
    const now = moment();
    const duration = moment.duration(now.diff(start));
    const previousDuration = moment.duration(previousElapsedTime);
    const totalDuration = duration.add(previousDuration);

    return `${String(totalDuration.hours()).padStart(2, '0')}:${String(totalDuration.minutes()).padStart(2, '0')}:${String(totalDuration.seconds()).padStart(2, '0')}`;
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      const dataEmail = await DEFAULT_COOKIE_GETTER("user");
      dispatch(callsData({ email: JSON.parse(dataEmail || "{}").email }));

      // Update elapsed times every second
      setElapsedTimes((prevTimes) => {
        const updatedTimes: TimerData = { ...prevTimes };
        Object.keys(updatedTimes).forEach((userId) => {
          updatedTimes[userId] = getElapsedTime(prevTimes[userId], updatedTimes[userId]);
        });
        return updatedTimes;
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const getStatusColor = useCallback((status: string, userId: string, timer: string) => {
    if (status === "open" || (status === 'open' && timer)) {
      try {
        const [hours, minutes, seconds] = timer.split(":").map(Number);
        const idleInSeconds = hours * 3600 + minutes * 60 + seconds;
        const maxIdleTimeInSeconds = 30 * 60; // 30 minutes in seconds

        if (idleInSeconds > maxIdleTimeInSeconds) {
          return "bg-red-500";
        }
      } catch (e) {
        return "bg-blue-500";
      }
    }

    switch (status) {
      case "idle":
        return "bg-blue-400";
      case "inactive":
        return "bg-gray-300";
      case "active":
        return "bg-green-500";
      case "open":
        return "bg-blue-500";
      case "inuse":
        return "bg-green-500";
      default:
        return "bg-gray-300";
    }
  }, []);

  const numColumns = dataCalls && dataCalls.calls ? dataCalls.calls.length : 1;
  const maxColumns = 7; // Optionally set a max number of columns
  const gridColumns = Math.min(numColumns, maxColumns);

  return (
    <>
    <div>
          <h2 className="font-semibold text-2xl">User's Live Dashboard</h2>
          <h5 className="font-semibold text-gray-400">Represents the Timer Running for each User and the Activity (In Use, Idle, Progressing)</h5>
        </div>
        
      <ToasterGen />
      <div className="relative mt-4 w-1/2">
  {isAdmin && ( // Conditionally render based on role
    <>
      <label className="mr-2">Select Manager:</label>
      <select
        value={selectedManager}
        onChange={(e) => handleManagerChange(e.target.value)}
        className="bg-gray-50 border border-gray-600 text-maincolor text-sm rounded-lg focus:ring-maincolor focus:border-maincolor block w-full pl-3 pr-10 p-2.5"
      >
        <option value="">-- Select Manager --</option>
        {managers.map((manager, index) => (
          <option key={index} value={manager}>
            {manager}
          </option>
        ))}
      </select>
    </>
  )}
</div>

<div className="flex flex-wrap mt-4">
  {userList.map((userId: string) => {
    const user = dataCalls.calls.find((item: any) => item.user === userId); // Find the user object
    const fullName = user ? `${user.first_name} ${user.last_name}` : userId; // Use full name or fallback to userId
    return (
      <label key={userId} className="flex items-center mr-4 mb-2" style={{ width: 'calc(12.5% - 1rem)' }}> {/* 12.5% for 8 items per row */}
        <input
          type="checkbox"
          checked={selectedUsers.includes(userId)}
          onChange={() => handleUserChange(userId)}
          className="mr-2"
        />
        {fullName}
      </label>
    );
  })}
</div>


      <div>
        
        <div className="grid w-full gap-4 mt-20" style={{ gridTemplateColumns: `repeat(${gridColumns}, 1fr)` }}>
          {dataCalls?.calls?.map((item: any, index: number) => {
            const isUserSelected = selectedUsers.includes(item.user);
            const isManagerTeamMember = managerTeam.includes(item.user);

            return (isUserSelected || ( isManagerTeamMember)) ? (
              <div key={index} className="flex flex-col bg-white border-2 border-solid shadow-lg p-4">
                <div className="ml-2 md:ml-4">
                  <p className="text-line font-dmsans text-lg font-semibold">
                    {item.first_name + " " + item.last_name}
                  </p>
                </div>
                <div className="lg:flex items-center justify-center md:justify-start mb-2">
                  <div className={`m-auto uppercase ${getStatusColor(item.presence, item.uid, item.timer)}`}>
                    <p className="p-8 text-white font-semibold break-all text-xs">
                      {item.presence === "open" ? 'IDLE' : item.presence === "inactive" ? "Offline" : item.presence}
                    </p>
                  </div>
                </div>
                {item.presence === "open" && (
                  <div className="ml-2 md:ml-4">
                    <p className="text-line font-dmsans text-md font-normal text-gray-400">
                      Idle Time: {item.timer}
                    </p>
                  </div>
                )}
              </div>
            ) : null;
          })}
        </div>
      </div>
    </>
  );
};

export default Main;
